import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import NavigationComponent from '@/components/NavigationComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { useRouter } from 'vue-router';
import { generalStore } from './store';
import { onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const router = useRouter();

const store = generalStore();

onMounted(() => {
  checkWidth();
  window.addEventListener('resize', checkWidth);
});

function checkWidth() {
  if (window.innerWidth <= 1300 && window.innerWidth >= 801) {
    store.isTablet = true;
  } else {
    store.isTablet = false;
  }

  if (window.innerWidth <= 800) {
    store.isMobile = true;
  } else {
    store.isMobile = false;
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(NavigationComponent, {
      "shorter-navigation": _unref(router).currentRoute.value.name !== 'LandingPage',
      "show-back-button": 
      _unref(router).currentRoute.value.name !== 'LandingPage' &&
      _unref(router).currentRoute.value.name !== 'OrderSuccessPage' &&
      _unref(router).currentRoute.value.name !== 'PaymentSuccessPage'
    
    }, null, 8, ["shorter-navigation", "show-back-button"]),
    _createVNode(_component_router_view),
    _createVNode(FooterComponent)
  ], 64))
}
}

})